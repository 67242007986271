import d3 from 'd3';
import * as topojson from "topojson-client";
import * as d3Transform from 'd3-transform';
import konami from 'konami-js';
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';

var width = 860,
    height = 1200;

var projection = d3.geo.mercator()
  .center([-73.90, 40.750])
  .scale(1200 * 120);

var path = d3.geo.path()
    .projection(projection);

var svg = d3.select("#map").append("svg")
      .attr("viewBox", "0 0 " + width + " " + height )
      .attr("preserveAspectRatio", "xMinYMin meet");

var poopGroup = svg.append("g").attr("id", "poop-group");
var landGroup = svg.append("g").attr("id", "land-group");
var advisoriesGroup = svg.append("g").attr("id", "advisories-group");

d3.json("nyc.topojson", function(error, data) {
  if (error) {
    console.error("Unable to load landforms:", error);
    return;
  }
  landGroup.append("path")
      .datum(topojson.feature(data, data.objects.nycminusrivers))
      .attr("d", path);
});

function loadAdvisories(filename) {
  d3.json(filename)
    .response((xhrResponse) => {
      var result = {data: JSON.parse(xhrResponse.responseText)},
          lastModifiedString = xhrResponse.getResponseHeader("Last-Modified");
      if (lastModifiedString) {
        var lastModified = Date.parse(lastModifiedString);
        if (!isNaN(lastModified)) {
          result.lastModified = new Date(lastModified);
        }
        // Last-Modified doesn't seem to be useful.
        result.lastModified = new Date();
      }
      return result;
    }).get(function(error, response) {
      if (error) {
        console.error("Unable to load advisories:", error);
        displayStatus("NOT SURE", "Maybe try again later?");
        return;
      }
      var data = response.data || [];
      var centroids = data.features
          //.filter(function(f) { return f.attributes['Advisory'] == 1 })
          .map(function(f) {
            f.type = 'Polygon';
            f.coordinates = f.geometry.rings;
            return projection(d3.geo.centroid(f)) });
      if (centroids.length > 0) {
        console.log("Centroids: ", centroids);
        generateTurdsNear(centroids);

        // Show the advisories as red shapes, which is nice for debugging.
        // advisoriesGroup.append("path")
        //   .datum(topojson.feature(uk, uk.objects.advisories))
        //   .attr("d", path)
        //   .attr("fill", "red");
        displayStatus("YES", "There is poop in the East River.");
      } else {
        displayStatus("NO", "No more than usual, anyway.");
      }
      displayLastUpdated(response.lastModified);
    });
}

//loadAdvisories("advisories.topojson");
// loadAdvisories("https://services.arcgis.com/at3rDjch5X7i9Bag/arcgis/rest/services/NYCWaterbodiesContaminationAdvisory45_View/FeatureServer/0/query?f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=%7B%22xmin%22%3A-8218509.2812173925%2C%22ymin%22%3A4931105.568727303%2C%22xmax%22%3A-8140237.7642533425%2C%22ymax%22%3A5009377.085691353%2C%22spatialReference%22%3A%7B%22wkid%22%3A4326%7D%7D&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*&outSR=4326&quantizationParameters=%7B%22mode%22%3A%22view%22%2C%22originPosition%22%3A%22upperLeft%22%2C%22tolerance%22%3A152.87405657040966%2C%22extent%22%3A%7B%22type%22%3A%22extent%22%2C%22xmin%22%3A912163.0673903084%2C%22ymin%22%3A118611.18933692576%2C%22xmax%22%3A1055182.5018235943%2C%22ymax%22%3A273599.4886128022%2C%22spatialReference%22%3A%7B%22wkid%22%3A3857%2C%22latestWkid%22%3A3857%7D%7D%7D")

loadAdvisories("https://services.arcgis.com/at3rDjch5X7i9Bag/arcgis/rest/services/cso_prd/FeatureServer/0/query?where=Advisory+%3D+1&f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=%7B%22xmin%22%3A-8296780.798181428%2C%22ymin%22%3A4931105.568727285%2C%22xmax%22%3A-8218509.281217378%2C%22ymax%22%3A5009377.085691333%2C%22spatialReference%22%3A%7B%22wkid%22%3A102100%7D%7D&geometryType=esriGeometryEnvelope&inSR=102100&outFields=Advisory&outSR=4326");

// For sunny days
new konami(() => loadAdvisories("konami.topojson"));

// Use two groups to "barber pole" the turds so they
// appear to be a continuous stream.
var groupA = poopGroup.append("g")
  .attr("class", "group-a");
var groupBContainer = poopGroup.append("g")
    .attr("transform", d3Transform.transform().translate(-20, 20))
  .attr("class", "group-b");
var groupB = groupBContainer.append("g");

function distance([p1x, p1y], [p2x, p2y]) {
  return Math.sqrt( Math.pow((p1x-p2x), 2) + Math.pow((p1y-p2y), 2));
}

function displayLastUpdated(date) {
  var node = d3.select("#last-updated").node();
  if (date) {
    node.textContent = `Last updated ${date.toLocaleString()}.`;
  } else {
    node.textContent = "";
  }
}

function displayStatus(title, body) {
  d3.select("#status-title").node().textContent = title;
  d3.select("#status-body").node().textContent = body;
}

function generateTurdsNear(nearPoints) {
  var points = [];
  var span = 20;
  for (var x = 0; x < width; x += span) {
    for (var y = 0; y < height; y += span) {
      var point = [x, y];
      if (nearPoints.some((nearPoint) => distance(point, nearPoint) < 50)) {
        points.push(point);
      }
    }
  }
  turdGenerator(groupA, points, 0);
  turdGenerator(groupB, points, 1);
}

// Also nice for debugging.
// svg.on("mousemove", mouseMoved);
// function mouseMoved() {
//   generateTurdsNear([d3.mouse(this)]);
// }

function turdGenerator(group, regions, delay) {
  var transform = d3Transform.transform()
        .translate(position => position)
        .scale(0.65);
  var join = group.selectAll("path")
        .data(regions)
        .attr("transform", transform);
  // Turd SVG is inline so we don't have to load it
  join.enter()
    .append("path")
    .attr("d", "M10.5,5.2C9.4,4.9,8,5.5,7.5,6.5S6.3,8.7,7.4,9.3C6.2,9,6.1,9.1,5.1,9.6 c-1.1,0.5-1.8,1.8-1.3,2.8C3.6,12,3,11.9,2.6,12.1c-0.4,0.2-0.7,0.6-0.9,1C1.1,14.3,0.5,16,1.4,17c0.4,0.4,1,0.6,1.5,0.8 c0.9,0.3,1.9,0.4,2.8,0.2s1.7-1.1,1.6-2c1.9-0.1,3.5-2,3.2-3.9c0.8,0.6,2-0.2,2.4-1.2s0.8-1.9,1-2.9c1.2,0.5,2.1,0,3-0.9 c1.8-0.9,0.9-3.9,2.4-5.2c-1-0.2-2.1-0.5-3.1-0.7c-0.5-0.1-1-0.2-1.6-0.1c-0.4,0.1-0.8,0.3-1.2,0.5C11.8,2.6,10.5,5.6,10.5,5.2z")
    .attr("fill", "#543412")
    .attr("transform", transform);
  join.exit().remove();
}
